<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Podcast"/><br>
             <audio src="" id="eventAudio" ref="eventAudio"></audio>
            <div class="row">
                <div v-for="(audio,index) in audios" :key="index" class="col-sm-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <iframe class="size" :src="audio.src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <span class="text-muted" style="font-size:12px; white-space:pre-wrap;">{{ audio.title }}</span>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            currentAudio: null,
            audios:[
                {key: 16, img: null, src: 'https://www.youtube.com/embed/1Q-a6x81CV0', title: 'Áreas Libres'},
                {key: 15, img: null, src: 'https://www.youtube.com/embed/hIpkxOL1g7s', title: 'Cuarentenas'},
                {key: 14, img: null, src: 'https://www.youtube.com/embed/JEwBOkaEfc8', title: 'Daños al fruto'},
                {key: 13, img: null, src: 'https://www.youtube.com/embed/AKCft64lOIg', title: 'Acompañamiento comunitario'},
                {key: 12, img: null, src: 'https://www.youtube.com/embed/B7LmrIjZ02U', title: 'Laboratorio de adultos de mosca del Mediterráneo'},
                {key: 11, img: null, src: 'https://www.youtube.com/embed/RyDDnR4OG58', title: 'Laboratorio de estados inmaduros de la mosca del Mediterráneo'},
                {key: 10, img: null, src: 'https://www.youtube.com/embed/jGvMyI_y9fs', title: 'Actividades de apoyo comunitario'},
                {key: 9, img: null, src: 'https://www.youtube.com/embed/iO_gfH9naA4', title: 'Producción de mosca del Mediterráneo estéril'},
                {key: 8, img: null, src: 'https://www.youtube.com/embed/42kK3VDlxks', title: 'Liberación de mosca del Mediterráneo estéril'},
                {key: 7, img: null, src: 'https://www.youtube.com/embed/Hm8MHIs-cos', title: 'Biología de la mosca del Mediterráneo'},
                {key: 6, img: null, src: 'https://www.youtube.com/embed/L309zSiG5x4', title: 'Estaciones Cebo'},
                {key: 5, img: null, src: 'https://www.youtube.com/embed/9lBhjIjnUA0', title: 'Control Mecánico'},
                {key: 4, img: 'sobre_mosca_mediterraneo.png', src: 'https://www.youtube.com/embed/GfBMkxjtYWc', title: 'Hablemos sobre la mosca del Mediterráneo'},
                {key: 3, img: 'deteccion_control_mosca_mediterraneo.png', src: 'https://www.youtube.com/embed/YKe4LXsG8K8', title: 'Qué es detección y control de la mosca del Mediterráneo'},
                {key: 2, img: 'beneficios_erradicar_mosca_mediterraneo.png', src: 'https://www.youtube.com/embed/cgacG9gfSq8', title: 'Cuáles son los beneficios de erradicar la mosca del Mediterráneo'},
                {key: 1, img: 'sobre_apoyo_sector_apicola.png', src: 'https://www.youtube.com/embed/7hd1J1rV7OA', title: 'Hablemos sobre el apoyo al sector apícola'},
            ],
        }
    },
    computed: {
        currentRouteName () {
            return this.$route.name;
        },
        cCurrenAudio () {
            return this.currentAudio
        }
    },
    methods: {
        playAudio: function (src, key) {
            let buttonAudio = document.getElementById('eventAudio');

            if (this.currentAudio == key) {
                buttonAudio.setAttribute('src', '')
                this.currentAudio = null
            } else {
                buttonAudio.setAttribute('src', src)
                buttonAudio.play()
                this.currentAudio = key
            }
        }
    }
}
</script>

<style scoped>
 hr {
         border-top: 1px solid white;
 }
</style>
